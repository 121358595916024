<template>
  <Modal
    @close-modal="turnOffLinePayNotification"
    class="modal-center modal-exchange-error"
    v-if="isLinePayValidationError"
  >
    <div slot="body">
      <p v-html="linePayValidationErrorMessage"/>

      <button @click.prevent="turnOffLinePayNotification"
              v-html="'閉じる'"
              class="btn-into-modal"/>
    </div>
  </Modal>
</template>

<script lang="js">
import Modal from '@/components/advertising-detail/Modal'

export default {
  name: 'ModalLinePayNotification',

  components: { Modal },

  props: {
    isLinePayValidationError: {
      type: Boolean,
      default: false
    },
    linePayValidationErrorMessage: {
      type: String,
      default: ''
    },
    turnOffLinePayNotification: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped>

</style>
