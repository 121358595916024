import store from '@/store'
import { mapState, mapActions } from 'vuex'
import { RESPONSE_STATUS } from '@/enum/response'
import { phoneBeforeRouteEnterValidation } from '@/utils/sns-validation'
import ModalVerifyAuthenMobile from '@/components/ModalVerifyAuthenMobile'
import ModalLinePayNotification from '@/components/ModalLinePayNotification'

export default {
  async beforeRouteEnter (to, from, next) {
    to.meta.ostiaries_error = to.query.ostiaries_error || undefined
    // linePayBeforeRouteEnterValidation(to, from, next)
    phoneBeforeRouteEnterValidation(to, from, next)
  },

  created () {
    if (this.$route.meta.ostiaries_error) {
      this.messageNotification = (this.$route.meta.ostiaries_error === 'true')
        ? '着信認証が完了しませんでした。<br/>再度お試しください。' : '電話番号の認証が完了しました！'
      this.flagModalNoti = true
    }
  },

  beforeRouteLeave (to, from, next) {
    store.commit('linePay/SET_LINE_TOKEN', null)
    store.commit('linePay/SET_REFERENCE_NUMBER', null)
    next()
  },

  components: {
    ModalVerifyAuthenMobile,
    ModalLinePayNotification
  },

  data () {
    return {
      pointExchange: null,
      flagModalInput: false,
      flagModalConfirm: false,
      flagModalComplete: false,
      flagModalNoti: false,
      dataComplete: null,
      flagModalRedirect: false,
      messageNotification: '',
      isLinePayValidationError: false,
      exchangeFailedMessage: 'エラーが発生しました。 <br/> 時間をおいてから再度お試しください。',
      flagModalVerifyAuth: false,
      linePayValidationErrorMessage: ''
    }
  },

  computed: {
    ...mapState('auth', ['user_point', 'profile', 'token']),
    ...mapState('linePay', ['referenceNumber', 'lineToken', 'point']),
    ...mapState('pointExchange', ['services']),

    pointExchangeService () {
      const path = this.$route.path
      const pathSegments = path.split('/')
      const lastPathName = pathSegments[pathSegments.length - 1]
      return this.services.find(service => service.path === lastPathName) || {}
    },

    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    },

    pointExchangePayload () {
      return { point: this.pointExchangeMapping() }
    },

    isLinePayAuthenticated () {
      return this.profile.is_authenticate
    },

    isExchangeAutoSuccess () {
      if (!this.dataComplete || !this.dataComplete.status) {
        return false
      } else {
        return (this.dataComplete.status === 4 || this.dataComplete.status === 7)
      }
    }
  },

  methods: {
    ...mapActions('auth', ['redirectUserSNS', 'userPoint']),

    pointExchangeMapping () {
      return this.pointExchange ? parseInt(this.pointExchange.replace(/,/g, '')) : 0
    },

    async onExchangePoint (cb) {
      this.flagModalConfirm = false
      const exchangeResult = await cb(this.pointExchangePayload)

      const { status, error = null, errors = [] } = exchangeResult

      if (this.checkWebview()) {
        this.triggerEventApp('CHANGE_POINT', {})
      } else {
        await this.userPoint()
      }

      if (status === RESPONSE_STATUS.OK) {
        const exchangeData = exchangeResult.data

        this.dataComplete = {
          ...(exchangeData.body) && exchangeData.body,
          ...(!exchangeData.body) && exchangeData
        }

        this.flagModalComplete = true
      } else {
        if (error) {
          this.messageNotification = error?.message || this.exchangeFailedMessage
        } else if (errors.length === 0) {
          this.messageNotification = this.exchangeFailedMessage
        } else {
          const exchangeErrors = errors[0].message
          const exchangeErrorMessage = exchangeErrors.length > 0 ? exchangeErrors[0] : this.exchangeFailedMessage
          this.messageNotification = exchangeErrorMessage
        }
        this.flagModalNoti = true
      }
    },

    handleAuthenticateLine () {
      this.flagModalConfirm = false
      this.loginLine()
    },

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.flagModalInput = false
        this.flagModalConfirm = true
      }
    },

    handleAuthenticatePhone (point, param, callback) {
      this.flagModalConfirm = false

      if (this.profile.skip_regis_info) {
        this.openRegisterRedirect()
      } else if (!this.profile.otp) {
        this.openPhoneAuthModal()
      } else if (callback) {
        callback()
      } else {
        this.flagModalInput = true
        this.pointExchange = null
        this.confirm = true

        // Only GiftPad
        if (point && param) {
          this.flagModalConfirm = true
          this.pointChoose = point
          this.paramRequest = param
        }
      }
    },

    linePaySNSRedirect () {
      const pointEx = this.pointExchange
      const pointFormat = pointEx ? pointEx.replace(/,/g, '') : 0
      if (this.checkWebview()) {
        this.triggerEventApp('OPEN_APP_LINE', {
          point: pointFormat ? parseInt(pointFormat) : 0
        })
      } else {
        this.openLinePaySNS()
      }
    },

    async openLinePaySNS () {
      const userSNS = await this.redirectUserSNS(['line'])
      localStorage.setItem('previous_route', this.$route.name)
      localStorage.setItem('point_exchange', this.pointExchange)

      if (userSNS.success) {
        this.openRedirectSNS(userSNS.data.url)
      }
    },

    loginLine () {
      if (!this.isLinePayAuthenticated) {
        this.openLinePaySNS()
      } else {
        this.flagModalInput = true
        this.pointExchange = null
      }
    },

    handleBeforeExchangePoint () {
      // if (this.profile.skip_regis_info) this.openRegisterRedirect()
      // else if (!this.profile.otp) this.openPhoneAuthModal()
      // else this.handleAuthenticateLine()
      this.handleAuthenticatePhone()
    },

    openRedirectSNS (url) {
      const link = document.createElement('a')
      link.setAttribute('href', url)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    turnOffLinePayNotification () {
      this.isLinePayValidationError = false
      this.linePayValidationErrorMessage = ''
    },

    turnOffNoti () {
      if (window.location.href.includes('?ostiaries_error')) {
        this.$router.replace({
          name: this.$route.name
        })
      }
      this.flagModalNoti = false
    },

    handleCloseModal () {
      this.flagModalComplete = false

      window.scrollTo({ top: 0, behavior: 'smooth' })

      if (!this.confirm) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },

    handleReturn () {
      this.flagModalNoti = false

      if (this.$route.meta.ostiaries_error === 'true') {
        this.flagModalVerifyAuth = true
      }

      if (window.location.href.includes('?ostiaries_error')) {
        this.$router.replace({
          name: this.$route.name
        })
      }
    },

    handelRedirectUpdateInfo () {
      this.$router.push({ name: 'ChangeProfile', params: { isChangePoint: true, name: this.$route.name } })
    },

    handleNumber (e) {
      this.pointExchange = e.replace(/\D/g, '')
    },

    handleNumberFocusOut () {
      this.pointExchange = new Intl.NumberFormat('ja-JP').format(this.pointExchange)
    },

    handleNumberFocusIn (e) {
      this.pointExchange = e.replace(/,/g, '')
    },

    openPhoneAuthModal () {
      this.flagModalVerifyAuth = true
    },

    openRegisterRedirect () {
      this.flagModalRedirect = true
    }
  }
}
