import store from '@/store'
import router from '@/router'
import { getExchangeServiceRoutes } from '@/utils/exchange-service'

export function redirectTo (to) {
  router.replace({ name: to.name })
}

export function getPreviousRoute () {
  const previousRoute = localStorage.getItem('previous_route')
  const exchangePointRoutes = getExchangeServiceRoutes()

  if (exchangePointRoutes.indexOf(previousRoute) === -1) return { name: 'Index' }

  // localStorage.removeItem('previous_route')
  localStorage.removeItem('point_exchange')

  return {
    name: previousRoute
  }
}

export async function phoneBeforeRouteEnterValidation (to, from, next) {
  if (!store.state.auth.token ||
    !to.query.code ||
    !to.query.state
  ) return next()

  const params = {
    sns_service: 'line',
    code: to.query.code || '',
    state: to.query.state || '',
    login: 'linepay'
  }

  const userLoginSNS = await store.dispatch('auth/userLoginSNS', ['line', params])

  if (!userLoginSNS.data) {
    const previousRoute = getPreviousRoute()
    return next(previousRoute)
  }

  const { linepay_access_token: linePayAccessToken } = userLoginSNS.data

  if (userLoginSNS.success) {
    const refNumberParams = {
      access_token: linePayAccessToken
    }

    await store.dispatch('linePay/getReferenceNumber', refNumberParams)

    next(vm => {
      vm.$data.pointExchange = to.query.point_exchange
      vm.$data.flagModalAllow = true
    })
  } else {
    next(vm => {
      vm.$data.flagModalNoti = true
    })
  }

  redirectTo(to)
}

export async function linePayBeforeRouteEnterValidation (to, from, next) {
  if (!store.state.auth.token ||
    !to.query.code ||
    !to.query.state
  ) return next()

  const params = {
    code: to.query.code || '',
    state: to.query.state || ''
  }

  const isLinePayAuthenticated = store.state.auth.profile.is_authenticate
  if (isLinePayAuthenticated) {
    redirectTo(to)

    return next(vm => {
      vm.$data.flagModalAllow = true
      vm.$data.pointExchange = to.query.point_exchange
    })
  }

  const userLoginSNS = await store.dispatch('auth/linePayAuthenticate', [params])
  if (userLoginSNS?.error?.message) {
    redirectTo(to)

    return next(vm => {
      vm.$data.isLinePayValidationError = true
      vm.$data.linePayValidationErrorMessage = userLoginSNS.error.message
    })
  }

  const { line_access_token: linePayAccessToken } = userLoginSNS

  if (userLoginSNS.success) {
    const params = {
      access_token: linePayAccessToken
    }

    await store.dispatch('linePay/getReferenceNumber', params)

    next(vm => {
      vm.$data.pointExchange = to.query.point_exchange
      vm.$data.flagModalInput = true
      // vm.$data.pointExchange = null
    })
  } else {
    next(vm => {
      vm.$data.flagModalNoti = true
    })
  }

  redirectTo(to)
}
